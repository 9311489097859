import { Grid, Typography, Box, Button, Stack, Checkbox, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import registration_bgImg from "../Images/registartion_bgImg.png";
import eris_registration_logo from "../Images/eris_registration_logo.svg";
import { FaChevronRight } from "react-icons/fa6";
import InputOtpComponent from "../InputOtp/InputOtpComponent";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import editImg from "../Images/editImg.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../axiosInstance";
import { useDispatch } from "react-redux";
import { login } from "../../reducers/authSlice";
import "./index.scss";
const learningService = require("../../api/learning");

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
  palette: {
    mode: "light",
  },
});

// const fontTheme = createMuiTheme({
//   typography: {
//     fontFamily: poppins,
//   },
// });

const Index = () => {
  const [consentFormSection, setConsentForSection] = useState(false);
  const [getOtpSection, setGetOtpSection] = useState(false);
  const [verifyOtpSection, setVerifyOtpSection] = useState(false);
  const [onTimerComplete, setOnTimerComplate] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [isActive, setIsActive] = useState(false);
  const [otp, setOtp] = useState("");
  const [registerMobileNumber, setRegisterMobileNumber] = useState("");
  const [username, setUsername] = useState("");
  const [videos, setVideos] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1); // Decrement seconds
      }, 1000); // Run every second (1000ms)
    } else if (seconds === 0) {
      setIsActive(false); // Deactivate timer when countdown ends
      setOnTimerComplate(true);
    }

    // Cleanup function to clear interval when component unmounts
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive, seconds, onTimerComplete]);

  const handleRegister = () => {
    setGetOtpSection(true);
  };

  const handleGetOtp = async () => {
    try {
      const phoneNumberPattern = /^\d{10}$/;

      if (!username || !registerMobileNumber) {
        toast.error("All fields are required", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Exit early if fields are not provided
      } else if (!phoneNumberPattern.test(registerMobileNumber)) {
        toast.error("Please enter a valid 10-digit phone number", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const response = await axiosInstance.post("/api/auth/register-using-phone-no", {
        phone_number: registerMobileNumber,
        name: username,
      });

      if (response.status === 200) {
        toast.success("OTP sent successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setGetOtpSection(false);
        setVerifyOtpSection(true);
        startTimer();
      } else {
        toast.error("Failed to send OTP. Please try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error in handleGetOtp:", error.message);
      if (error.response) {
        // Server responded with a status code other than 2xx
        if (error.response.status === 409) {
          toast.error(error.response.data.message || "User alredy exit", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.response.status === 404) {
          toast.error("User doesn't exist ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Server error. Please try again later.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        // Network or other errors
        toast.error("Network error. Please check your connection and try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleVerifyOtp = async () => {
    try {
      if (!otp || !registerMobileNumber || !username) {
        toast.error("All fields are required", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Exit early if fields are not provided
      }

      const response = await axiosInstance.post("/api/auth/validate-otp-for-reg", {
        phone_number: registerMobileNumber,
        otp,
        name: username,
      });

      if (response.status === 200) {
        toast.success("OTP verified successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(
          login({
            userId: response.data.user.user_id,
            userName: response.data.user.name,
            phone_number: response.data.user.phone_number,
          })
        );
        localStorage.setItem("token", response.data.token);

        setVerifyOtpSection(false);
        setConsentForSection(true);
      } else {
        toast.error("Invalid OTP or other error. Please try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error in handleVerifyOtp:", error.message);
      toast.error("An error occurred during OTP verification. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    async function fetchVideos() {
      try {
        const videosData = await learningService.getVideos();
        setVideos(videosData);
      } catch (error) {
        console.error("Error in fetchVideos:", error.message);
      }
    }
    fetchVideos();
  }, []);

  const handleResendOTP = async () => {
    try {
      if (!username || !registerMobileNumber) {
        toast.error("All fields are required", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Exit early if fields are not provided
      }

      const response = await axiosInstance.post("/api/auth/register-using-phone-no", {
        phone_number: registerMobileNumber,
        name: username,
      });

      if (response.status === 200) {
        toast.success("OTP sent successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setGetOtpSection(false);
        setVerifyOtpSection(true);
        startTimer();
      } else {
        toast.error("Failed to send OTP. Please try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error in handleGetOtp:", error.message);
      if (error.response) {
        // Server responded with a status code other than 2xx
        if (error.response.status === 409) {
          toast.error(error.response.data.message || "User alredy exit", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.response.status === 404) {
          toast.error("User doesn't exist ", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Server error. Please try again later.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        // Network or other errors
        toast.error("Network error. Please check your connection and try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    setOnTimerComplate(false);
    startTimer();
  };

  const navigate = useNavigate();

  const startTimer = () => {
    setIsActive(true); // Activate the timer
    setSeconds(30); // Reset seconds to 30 when starting
  };

  const handleEditNumber = () => {
    setVerifyOtpSection(false);
    setGetOtpSection(true);
  };

  const ConsentTextData = [
    {
      id: 1,
      "Data Collection":
        "Your personal and medical information will be collected and stored securely.",
    },
    {
      id: 2,
      "Medical Treatment": " Your treatment will be managed under the guidance of Dr. [Doctor].",
    },
    {
      id: 3,
      Communication: "You consent to receive updates and notifications via SMS or email.",
    },
  ];

  const TermsAndConditionsText = [
    "Your data will only be used for medical purposes",
    "You have the right to request access to your data and ask for corrections.",
    "ou may withdraw your consent at any time by contacting our support team.",
  ];

  const registration = (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          item
          marginTop={"35px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={"7px"}
        >
          <img src={eris_registration_logo} height={"25vh"} />
          <Typography
            variant="p"
            color={"#F47458"}
            fontSize={"10px"}
            fontWeight={"500"}
            display={"flex"}
            justifyContent={"center"}
            marginRight={"5px"}
          >
            Patient Care Initiative
          </Typography>
        </Grid>
        <Grid
          item
          display={"flex"}
          gap={"10px"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          {/* Replace image with video */}
          <video
            src={videos&&videos[0]?.link || "https://eris-marketing-applications.s3.ap-south-1.amazonaws.com/glimisave/Glimisave+shorts.mp4"}
            height={"175px"}
            controls
            autoPlay
            loop
            muted
          />
  
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            marginTop={"10px"}
            gap={"10px"}
          >
            <Typography variant="p" color={"#F47458"} fontWeight={"500"} fontSize={"16px"}>
              Medication Reminders
            </Typography>
            <Typography
              width={"60%"}
              display={"flex"}
              align={"center"}
              color={"#0059A5"}
              fontWeight={"500"}
              variant="p"
              fontSize={"13px"}
            >
              Get timely SMS reminders to take your medicine
            </Typography>
          </Box>
        </Grid>
  
        <Grid item display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"15px"}>
          <Button
            sx={{
              bgcolor: "#0059A5",
              color: "white",
              width: "260px",
              fontWeight: "500",
              fontSize: "12px",
              borderRadius: "6px",
              textTransform: "none",
            }}
            onClick={handleRegister}
          >
            Register with Mobile No. <FaChevronRight />
          </Button>
          <Button
            sx={{
              bgcolor: "white",
              color: "0059A5",
              width: "260px",
              fontWeight: "600",
              fontSize: "12px",
              border: "2px solid #0059A5",
              borderRadius: "6px",
              textTransform: "none",
            }}
            onClick={() => navigate("/home")}
          >
            Get Started as Guest
          </Button>
          <Typography marginTop={"5px"} color={"#707179"} fontSize={"13px"}>
            Already have an account?{" "}
            <button
              style={{
                backgroundColor: "white",
                border: "none",
                color: "#2B72E6",
                textDecoration: "underline",
                padding: "0px",
              }}
              onClick={() => navigate("/login")}
            >
              {" "}
              Log in
            </button>
          </Typography>
        </Grid>
      </ThemeProvider>
    </>
  );
  

  const censentForm = (
    <>
      <Grid
        item
        marginTop={"40px"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={"7px"}
      >
        <img src={eris_registration_logo} height={"25px"} />
        <Typography
          variant="p"
          color={"#F47458"}
          fontSize={"10px"}
          fontWeight={"500"}
          display={"flex"}
          justifyContent={"center"}
          marginRight={"5px"}
        >
          Patient Care Initiative
        </Typography>
      </Grid>
      <Grid
        marginTop={3}
        display={"flex"}
        justifyContent={"center"}
        // flexDirection={"column"}
        alignItems={"center"}
        item
      >
        <Typography fontSize={"20px"}>Consent Form</Typography>
      </Grid>

      <Grid marginTop={2}>
        <Typography>Patient Consent Agreement:</Typography>
      </Grid>
      <Grid item marginTop={1}>
        <Typography color={"#727272"} fontSize={"12px"}>
          By proceeding, you acknowledge and agree to the following:
        </Typography>
      </Grid>

      <Grid container marginTop={1}>
        {ConsentTextData.map((item) => {
          const key = Object.keys(item)[1];
          return (
            <Grid marginTop={1} key={item.id} item>
              <Typography
                fontSize={"12px"}
                color={"#727272"}
              >{`${item.id}. ${key}: ${item[key]}`}</Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid container marginTop={2}>
        <Typography color={"#727272"}>Terms And Conditions</Typography>
        {TermsAndConditionsText.map((item) => (
          <Grid item>
            <Typography fontSize={"12px"} color={"#727272"} marginTop={1}>
              • {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container marginTop={3}>
        <Grid item>
          <Typography fontSize={"13px"} color={"#95969C"}>
            By clicking{" "}
            <Box component={"span"} color={"#3B82F6"}>
              "Agree and Continue"
            </Box>{" "}
            you confirm that you have read and understood the terms and conditions.'
          </Typography>
        </Grid>
        <Grid item marginTop={2}>
          <Stack justifyContent={"center"} alignItems={"center"} direction={"row"}>
            <Checkbox />
            <Typography fontSize={"12px"} color={"#7F7F7FC4"}>
              I agree to the terms and conditions
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => navigate("/home")}
            fullWidth
            size="large"
            variant="outlined"
            sx={{
              ":hover": {
                color: "#3B82F6",
              },
              color: "white",
              bgcolor: "#0059A5",
              borderRadius: "4px",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          >
            <Typography
              fontSize={"14px"}
              textTransform={"none"}
              // marginBottom={"50px"}
            >
              Agree and Continue
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const getOtpForm = (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          item
          marginTop={"35px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={"7px"}
        >
          <img src={eris_registration_logo} height={"25px"} />
          <Typography
            variant="p"
            color={"#F47458"}
            fontSize={"10px"}
            fontWeight={"500"}
            display={"flex"}
            justifyContent={"center"}
            marginRight={"5px"}
          >
            Patient Care Initiative
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight={"600"} color={"#4E4E55"} marginBottom={"30px"}>
            We need following information to send you reminders{" "}
          </Typography>
          <Box>
            <label style={{ color: "#707179", fontSize: "13px", fontWeight: "600" }}>
              Your Name
            </label>
            <input
              placeholder="Enter your name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                padding: "8px",
                fontSize: "13px",
                width: "92%",
                marginBottom: "20px",
                marginTop: "4px",
                border: "1px solid #64748B",
                borderRadius: "5px",
              }}
            />

            <label style={{ color: "#707179", fontSize: "13px", fontWeight: "600" }}>
              Your Mobile Number
            </label>
            <input
              placeholder="Enter your mobile number"
              type="number"
              value={registerMobileNumber}
              onChange={(e) => setRegisterMobileNumber(e.target.value)}
              style={{
                padding: "8px",
                fontSize: "13px",
                width: "92%",
                marginBottom: "20px",
                marginTop: "4px",
                border: "1px solid #64748B",
                borderRadius: "5px",
              }}
            />
          </Box>
          <Button
            onClick={handleGetOtp}
            sx={{
              bgcolor: "#0059A5",
              color: "white",
              width: "98%",
              marginTop: "20px",
              borderRadius: "6px",
              textTransform: "none",
            }}
          >
            {" "}
            Get OTP{" "}
          </Button>
        </Grid>
      </ThemeProvider>
    </>
  );

  const verifyOtpForm = (
    <>
      <>
        <Grid
          item
          marginTop={"35px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={"7px"}
        >
          <img src={eris_registration_logo} height={"25px"} />
          <Typography
            variant="p"
            color={"#F47458"}
            fontSize={"10px"}
            fontWeight={"500"}
            display={"flex"}
            justifyContent={"center"}
            marginRight={"5px"}
          >
            Patient Care Initiative
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight={"600"} color={"#4E4E55"} marginBottom={"40px"}>
            We need following information to send you reminders{" "}
          </Typography>

          <Typography
            marginTop={"40px"}
            color={"#332D2D"}
            // display={"flex"}
            // gap={"15px"}
            sx={{ marginTop: "6px", marginBottom: "20px" }}
          >
            <p
              style={{
                color: "#707179",
                fontSize: "13px",
                display: "block",
                margin: "8px 0px",
              }}
            >
              We just sent a 4-digit code to
            </p>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <Typography
                color="#78A5FE"
                sx={{
                  textDecoration: "underline",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                {" "}
                +91 {registerMobileNumber}
              </Typography>
              <Typography
                onClick={() => handleEditNumber()}
                color="#B2B2B2"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                  fontSize: "12px",
                }}
              >
                {" "}
                <img src={editImg} alt="Edit Img" height="11px" /> Edit
              </Typography>
            </Box>
          </Typography>

          <ThemeProvider theme={theme}>
            <InputOtpComponent value={otp} onChange={setOtp} length={4} />
          </ThemeProvider>
          <Button
            onClick={handleVerifyOtp}
            sx={{
              bgcolor: "#0059A5",
              color: "white",
              width: "98%",
              marginTop: "20px",
              borderRadius: "6px",
            }}
          >
            {" "}
            Verify Mobile No.
          </Button>

          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              gap: "3px",
              fontSize: "13px",
              marginTop: "20px",
              // fontWeight: "600",
            }}
          >
            Didn't get the OTP?{" "}
            {onTimerComplete ? (
              <Typography
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleResendOTP()}
                color={"#3B82F6"}
                fontSize={"13px"}
                // fontWeight={"600"}
              >
                {" "}
                Resend OTP{" "}
              </Typography>
            ) : (
              <Typography
                color={"#A6A6A6"}
                fontSize={"13px"}
                // fontWeight={"600"}
              >
                {" "}
                Resend OTP in {seconds}s
              </Typography>
            )}
          </Typography>
        </Grid>
      </>
    </>
  );

  return (
    <>
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        alignContent={"center"}
        // justifyContent={"center"}
        height={"100vh"}
        gap={"50px"}
        // width={"90%"}
        paddingLeft={"20px"}
        paddingRight={"20px"}
      >
        {!getOtpSection && !verifyOtpSection && !consentFormSection && registration}

        {getOtpSection && !verifyOtpSection && !consentFormSection && getOtpForm}

        {!getOtpSection && verifyOtpSection && !consentFormSection && verifyOtpForm}

        {!getOtpSection && !verifyOtpSection && consentFormSection && <Grid>{censentForm}</Grid>}
      </Grid>
    </>
  );
};

export default Index;
