import React from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Cooming_soon from "./Components/Cooming_soon";
// import Registration from "./Components/Registration/index"
import Registration from "./Components/Registration"
import Home from "./Components/Home"
import Login from "./Components/Login"
import { useSelector } from "react-redux";
import RegisterPopUp from "./Components/RegisterPopUp";
import ProfileSetting from "./Components/ProfileSetting/ProfileSetting";
import Admin from "./Components/Admin";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const phone_number = useSelector((state) => state.auth?.phone_number || null);

  const PrivateRoutes = () => {
    return isAuthenticated ? <Outlet /> : <Navigate to="/home" />;
  };

  const PublicRoutes = () => {
    return !isAuthenticated ? <Outlet /> : <Navigate to="/home" />;
  };

  const AdminRoute = () => {
    return isAuthenticated && (phone_number === "+919328039045" || phone_number === "+917405105778" || phone_number === "+917995551652") ? (
      <Outlet />
    ) : (
      <Navigate to="/home" />
    );
  };

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterPopUp />} />
          <Route path="/profileSetting" element={<ProfileSetting />} />
        </Route>

        <Route element={<PrivateRoutes />}>
        <Route element={<AdminRoute />}>
            <Route path="/admin" element={<Admin />} />
        </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
