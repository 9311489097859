import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
const reminderService = require('../../api/reminders');

const Admin = () => {
  const [sendedMessagesCount, setSendedMessagesCount] = useState(0);

  const handleDownload = async () => {
    try {
      const res = await reminderService.getUsers();
      const csvData = convertToCSV(res);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'users_data.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading data:', error);
    }
  };

  const convertToCSV = (data) => {
    const headers = ['Name', 'Created On', 'Phone no'];
    const csvRows = data.map((row) => [row.name, row.created_on, row.phone_number].join(','));
    return [headers.join(','), ...csvRows].join('\n');
  };

  useEffect(() => {
    const fetchMessageCount = async () => {
      try {
        const response = await reminderService.getCountOfSendedMessages();
        setSendedMessagesCount(response[0]?.total_count || 0); // Safely access the count
      } catch (error) {
        console.error('Error fetching message count:', error);
      }
    };

    fetchMessageCount();
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column" // Stack children vertically
      sx={{
        bgcolor: 'white',
        width: '92%',
        marginLeft: '5px',
        padding: '20px', // Increased padding for better spacing
        borderRadius: '5px',
        fontSize: '12.5px',
        fontWeight: '600',
        height: '100vh',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleDownload}
        sx={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }} // Add margin bottom
      >
        Download Data
      </Button>
      <Grid
        sx={{
          fontSize: '12px',
          fontWeight: '600',
          marginTop: '10px',
          color: '#333', // Set text color
          backgroundColor: '#f5f5f5', // Light background for better visibility
          padding: '10px', // Add padding for the message count display
          borderRadius: '5px', // Rounded corners
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Add subtle shadow
        }}
      >
        Total Messages Sent: {sendedMessagesCount}
      </Grid>
    </Grid>
  );
};

export default Admin;
