import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import homeImg from "../Images/headerHome.png";
import profileImg from "../Images/headerProfile.png";
import settingImg from "../Images/headerSetting.png";
import logoutImg from "../Images/headerLogout.png";
import erisLogo from "../Images/eris_registration_logo.svg";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducers/authSlice";

const Header = () => {
  const navigate = useNavigate();
  const [openAccountPopUp, setOpenAccountPopUp] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const phone_number = useSelector((state) => state.auth?.phone_number || null);


  const handleOpenAccountPopUp = () => {
    setOpenAccountPopUp(true);
  };

  const handleCloseAccountPopUp = () => {
    setOpenAccountPopUp(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleCloseAccountPopUp();
    }
  };

  useEffect(() => {
    if (openAccountPopUp) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openAccountPopUp]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottom={"1px solid #D5DDEC"}
        sx={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 10,
          backgroundColor: "#fff", // Ensure background color to prevent overlap
        }}
      >
        <Button onClick={() => navigate("/home")}>
          <img src={homeImg} style={{ height: "55px" }} alt="Home" />
        </Button>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <img src={erisLogo} height={"20px"} alt="Eris Logo" />
          <Typography
            variant="body2"
            color={"#F47458"}
            fontSize={"9px"}
            fontWeight={"500"}
            display={"flex"}
            justifyContent={"center"}
            marginRight={"5px"}
          >
            Patient Care Initiative
          </Typography>
        </Box>

        <Button onClick={handleOpenAccountPopUp}>
          <img src={profileImg} style={{ height: "55px" }} alt="Profile" />
        </Button>

        {openAccountPopUp && (
          <Box
            ref={dropdownRef}
            sx={{
              position: "absolute",
              zIndex: "100",
              top: "75%",
              right: "0%",
              transform: "translate(-20%, -50%)",
              width: 130,
              bgcolor: "background.paper",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
              p: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <Stack spacing={2} sx={{ width: "100%" }}>
            {(phone_number === "+919328039045" || phone_number === "+917405105778" || phone_number === "+917995551652") && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    gap: "8px",
                  }}
                  onClick={() => navigate("/admin")}
                >
                  <img src={settingImg} alt="Admin" height={"20px"} />
                  <Typography sx={{ fontSize: "14.5px", color: "#28A745" }}>
                    Admin
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "8px",
                }}
                onClick={handleLogout}
              >
                <img src={logoutImg} alt="Log Out" height={"20px"} />
                <Typography sx={{ fontSize: "14.5px", color: "#FF4E4E" }}>
                  Log Out
                </Typography>
              </Box>
            </Stack>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Header;
