import React, { useState, useEffect } from "react";
import eris_registration_logo from "../Images/eris_registration_logo.svg";
import { Typography, Grid, Button, Box, BottomNavigation } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import InputOtpComponent from "../InputOtp/InputOtpComponent";
import editImg from "../Images/editImg.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../axiosInstance";
import { useDispatch } from "react-redux";
import { login } from "../../reducers/authSlice";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const Index = () => {
  const [loginMobileNumber, setLoginMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [onTimerComplete, setOnTimerComplate] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [isActive, setIsActive] = useState(false);

  const [getOtpSection, setGetOtpSection] = useState(true);
  const [verifyOtpSection, setVerifyOtpSection] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1); // Decrement seconds
      }, 1000); // Run every second (1000ms)
    } else if (seconds === 0) {
      setIsActive(false); // Deactivate timer when countdown ends
      setOnTimerComplate(true);
    }

    // Cleanup function to clear interval when component unmounts
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive, seconds, onTimerComplete]);

  const handleGetOtp = async () => {
    const phoneNumberPattern = /^\d{10}$/;

    if (!loginMobileNumber) {
      toast.error("All fields are required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!phoneNumberPattern.test(loginMobileNumber)) {
      toast.error("Please enter a valid 10-digit phone number", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    } else {
      try {
        const response = await axiosInstance.post("/api/auth/send-otp", {
          phone_number: loginMobileNumber,
        });

        if (response.status === 200) {
          setGetOtpSection(false);
          setVerifyOtpSection(true);
          startTimer();
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Server error. Please try again later.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      toast.error("OTP is required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const response = await axiosInstance.post("/api/auth/validate-otp", {
        phone_number: loginMobileNumber,
        otp,
      });

      if (response.status === 200) {
        // Successful OTP verification
        localStorage.setItem("token", response.data.token);

        dispatch(
          login({
            userId: response.data.user.user_id,
            userName: response.data.user.name,
            phone_number: response.data.user.phone_number,
          })
        );

        toast.success("OTP verified successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        navigate("/home");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);

      if (error.response) {
        // Server responded with a status code other than 2xx
        if (error.response.status === 400) {
          toast.error(error.response.data.message || "Invalid OTP", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.response.status === 404) {
          toast.error("User doesn't exist or OTP not valid", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Server error. Please try again later.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        // Network or other errors
        toast.error("Network error. Please check your connection and try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await axiosInstance.post("/api/auth/send-otp", {
        phone_number: loginMobileNumber,
      });

      if (response.status === 200) {
        setGetOtpSection(false);
        setVerifyOtpSection(true);
        startTimer();
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Server error. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    setOnTimerComplate(false);
    startTimer();
  };

  const handleEditNumber = () => {
    setVerifyOtpSection(false);
    setGetOtpSection(true);
  };

  const startTimer = () => {
    setIsActive(true); // Activate the timer
    setSeconds(30); // Reset seconds to 30 when starting
  };

  const getOtpForm = (
    <>
      <Grid
        item
        marginTop={"35px"}
        marginBottom={"35px"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={"7px"}
      >
        <img src={eris_registration_logo} height={"25px"} />
        <Typography
          variant="p"
          color={"#F47458"}
          fontSize={"10px"}
          fontWeight={"500"}
          display={"flex"}
          justifyContent={"center"}
          marginRight={"5px"}
        >
          Patient Care Initiative
        </Typography>
      </Grid>
      <Grid item padding={"20px 30px"}>
        <Typography fontWeight={"600"} color={"#4E4E55"} marginBottom={"30px"}>
          Welcome Back!{" "}
          <p style={{ margin: "5px 0px", fontSize: "12px", color: "#95969C" }}>
            Enter Your Mobile Number To Log In
          </p>
        </Typography>
        <Box marginTop={"80px"}>
          <label style={{ color: "#707179", fontSize: "13px", fontWeight: "600" }}>
            Your Mobile Number
          </label>
          <input
            placeholder="Enter your mobile number"
            type="number"
            value={loginMobileNumber}
            onChange={(e) => setLoginMobileNumber(e.target.value)}
            style={{
              padding: "8px",
              fontSize: "13px",
              width: "92%",
              marginBottom: "20px",
              marginTop: "4px",
              border: "1px solid #64748B",
              borderRadius: "5px",
            }}
          />
        </Box>
        <Button
          onClick={handleGetOtp}
          sx={{
            bgcolor: "#0059A5",
            color: "white",
            width: "98%",
            marginTop: "20px",
            fontSize: "12px",
            borderRadius: "6px",
          }}
        >
          {" "}
          Get Otp
        </Button>

        <Typography
          marginTop={"25px"}
          color={"#707179"}
          fontSize={"13px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"4px"}
        >
          Don't have an Account?{" "}
          <button
            style={{
              backgroundColor: "white",
              border: "none",
              color: "#2B72E6",
              textDecoration: "underline",
              padding: "0px",
            }}
            onClick={() => navigate("/")}
          >
            {" "}
            Register here
          </button>
        </Typography>
      </Grid>
    </>
  );

  const verifyOtpForm = (
    <>
      <>
        <Grid
          item
          marginTop={"35px"}
          marginBottom={"35px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={"7px"}
        >
          <img src={eris_registration_logo} height={"25px"} />
          <Typography
            variant="p"
            color={"#F47458"}
            fontSize={"10px"}
            fontWeight={"500"}
            display={"flex"}
            justifyContent={"center"}
            marginRight={"5px"}
          >
            Patient Care Initiative
          </Typography>
        </Grid>
        <Grid item padding={"20px 30px"}>
          <Typography fontWeight={"600"} color={"#4E4E55"} marginBottom={"40px"}>
            We need following information to send you reminders{" "}
          </Typography>

          <Typography
            marginTop={"40px"}
            color={"#332D2D"}
            // display={"flex"}
            // gap={"15px"}
            sx={{ marginTop: "6px", marginBottom: "20px" }}
          >
            <p
              style={{
                color: "#707179",
                fontSize: "13px",
                display: "block",
                margin: "8px 0px",
              }}
            >
              We just sent a 4-digit code to
            </p>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <Typography
                color="#78A5FE"
                sx={{
                  textDecoration: "underline",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                {" "}
                +91 {loginMobileNumber}
              </Typography>
              <Typography
                onClick={() => handleEditNumber()}
                color="#B2B2B2"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                  fontSize: "12px",
                }}
              >
                {" "}
                <img src={editImg} alt="Edit Img" height="11px" /> Edit
              </Typography>
            </Box>
          </Typography>

          <ThemeProvider theme={theme}>
            <InputOtpComponent value={otp} onChange={setOtp} length={4} />
          </ThemeProvider>
          <Button
            onClick={handleVerifyOtp}
            sx={{
              bgcolor: "#0059A5",
              color: "white",
              width: "98%",
              marginTop: "20px",
              borderRadius: "6px",
              fontSize: "12px",
            }}
          >
            {" "}
            Verify Mobile No.
          </Button>

          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              gap: "3px",
              fontSize: "13px",
              marginTop: "20px",
              // fontWeight: "600",
            }}
          >
            Didn't get the OTP?{" "}
            {onTimerComplete ? (
              <Typography
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleResendOTP()}
                color={"#3B82F6"}
                fontSize={"13px"}
                // fontWeight={"600"}
              >
                {" "}
                Resend OTP{" "}
              </Typography>
            ) : (
              <Typography
                color={"#A6A6A6"}
                fontSize={"13px"}
                // fontWeight={"600"}
              >
                {" "}
                Resend OTP in {seconds}s
              </Typography>
            )}
          </Typography>
        </Grid>
      </>
    </>
  );

  return <>{getOtpSection ? getOtpForm : verifyOtpForm}</>;
};

export default Index;
